<template>
  <div class="page404">
    <img src="../assets/images/img404.png" alt="" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.page404 {
  width: 100%;
  text-align: center;
  img {
    margin: 0 auto;
    width: 30%;
  }
}
</style>
